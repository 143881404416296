<template>
  <custom-assessment
      :key="customAssessmentKey"
      :on-mount="loadOnMount"
      :prev-task="prevTask"
      :on-prev="hasPrevTask ? loadPrevTask : false"
      :next-task="nextTask"
      :on-next="hasNextTask ? loadNextTask : false"
  >
    <!--  суть проверки в том, чтобы показывать выбор оценки только для эксперта,
    т.к. есть смежная роль "проверяющий" у которого по сути функционал в режиме ридонли !-->
    <template #header>
      <div class="custom-assessment__header">
        <div class="custom-assessment__header__user-icon">
          <img
              src="@/assets/img/svg/user.svg"
              alt="user"
          />
        </div>

        <h2 class="custom-assessment__header__title">
          Респондент: {{respondent?.fullName}}
        </h2>
      </div>
    </template>
    <template #prepend-chat>
      <div class="custom-assessment__title">Оценка</div>
      <div class="custom-assessment__form">
        <div
            class="form__group"
            v-for="(scale, index) in scales"
            :key="scale.uuid"
        >
          <app-select
              class="mt-select_form-select custom-assessment__form__select"
              :items="scale.grades.map((grade) => ({
                value: grade,
                label: grade,
              }))"
              :errors="v$?.scales?.$each?.$response?.$errors[index] ? v$?.scales?.$each?.$response?.$errors[index].value : []"
              :label="scale.name"
              hide-details
              item-text="label"
              item-value="value"
              v-model="scale.value"
              @update:modelValue="needToSave = true"
          />
        </div>
      </div>
      <div class="custom-assessment__form custom-assessment__form--action">
        <div class="form__group">
          <mt-button
              class="form__btn custom-assessment__form__btn"
              @click="setMark()"
              :loading="setMarkRequestPending"
          >
            Сохранить
          </mt-button>

          <app-checkbox
              v-model="closeAfterEvaluation"
              hide-details
              class="form__group__checkbox"
              @update:modelValue="changeCloseAfterEvaluation"
          >
            <template #label>
              Закрыть после сохранения
            </template>
          </app-checkbox>
        </div>
      </div>
    </template>
  </custom-assessment>
</template>

<script>
import mtButton from '@/components/UI/mtButton/index.vue';
import CustomAssessment from '@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessment.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import CustomAssessmentApi from "@/api/custom-assessment.api";
import useVuelidate from "@vuelidate/core";
import {helpers} from "@vuelidate/validators";
import {useValidations} from "@/plugins/vuelidate-wrapper";
import useToast from "@/components/UI/AppToast/useToast.js";
import AppCheckbox from "@/components/UI/AppCheckbox/AppCheckbox.vue";

export default {
  name: "expertCustomAssessment",
  components: {AppCheckbox, AppSelect, CustomAssessment, mtButton},
  data () {
    return {
      setMarkRequestPending: false,
      prevTask: false,
      nextTask: false,
      needToSave: false,
      closeAfterEvaluation: !!localStorage.getItem('closeAfterEvaluation') ? localStorage.getItem('closeAfterEvaluation') === 'true' : false,
      customAssessmentKey: 1
    }
  },
  setup() {
    return {
      toast: useToast().toast,
      v$: useVuelidate({
        $lazy: true
      }),
    }
  },
  validations() {
    const { required } = useValidations();

    return {
      scales: {
        $each: helpers.forEach({
          value: {
            required
          }
        })
      }
    }
  },

  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'respondents']),
    ...mapState('assessmentPlans/plan/task', ['mark', 'scales', 'sessionUuid']),
    ...mapGetters(['isExpert']),
    planUuid() {
      return this.$route.params.planUuid;
    },
    taskUuid() {
      return this.$route.params.taskUuid;
    },
    task() {
      return this.respondent?.tests.find((item) => item.uuid === this.taskUuid);
    },
    respondentUuid() {
      return this.$route.params.respondentUuid;
    },
    respondent() {
      if(!this.respondentUuid) return null;
      return this.respondents.find((item) => item.uuid === this.respondentUuid);
    },

    hasNextTask() {
      if (!this.respondent) return false;

      const currentTaskIndex = this.respondent.tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getNextTask(currentTaskIndex, currentRespondentIndex);

      if(result) {
        this.nextTask = result;
      } else {
        this.nextTask = false;
      }

      return this.nextTask;
    },

    hasPrevTask() {
      if (!this.respondent) return false;

      const currentTaskIndex = this.respondent.tests.findIndex((item) => item.uuid === this.taskUuid);
      const currentRespondentIndex = this.respondents.findIndex((item) => item.uuid === this.respondentUuid);
      const result = this.getPrevTask(currentTaskIndex, currentRespondentIndex);

      if(result) {
        this.prevTask = result;
      } else {
        this.prevTask = false;
      }

      return this.prevTask;
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['getScales', 'getMark', 'getUserSession']),
    ...mapMutations('assessmentPlans/plan/task', ['updateStore']),
    ...mapActions('assessmentPlans/plan', ['setPlanProgress']),

    async setMark() {
      const isValid = await this.v$.$validate();
      if(!isValid) return

      this.setMarkRequestPending = true;

      const payload = this.scales.map((item) => {
        return {
          uuid: item.uuid,
          grade: item.value
        }
      });
      CustomAssessmentApi.setMark(
          this.planUuid, 
          this.taskUuid, 
          this.sessionUuid,
          payload, 
      )
          .then(() => {
            this.setPlanProgress({
              uuid: this.planUuid,
            });
            this.getMark({
              planUuid: this.planUuid,
              taskUuid: this.taskUuid,
              sessionUuid: this.sessionUuid
            });
            this.needToSave = false;
            this.$toast.success("Оценка успешно выставлена");
            this.updateStore({ entity: 'marksChanged', payload: true })
            if(this.closeAfterEvaluation) {
              this.$router.go(-1);
            }
          })
          .finally(() => {
            this.setMarkRequestPending = false;
          })
    },

    async loadOnMount() {
      await this.getUserSession({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        respondentUuid: this.respondentUuid
      });
      await this.getScales({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        sessionUuid: this.sessionUuid
      });
    },

    loadNextTask() {
      if(this.needToSave) return this.toast.error(`Нужно сохранить оценку, чтобы перейти к следующему заданию/респонденту`);
      this.v$.$reset();

      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.nextTask.uuid,
          respondentUuid: this.nextTask.respondentUuid
        }
      })
      this.customAssessmentKey++;
    },

    loadPrevTask() {
      if(this.needToSave) return this.toast.error(`Нужно сохранить оценку, чтобы перейти к предыдущему заданию/респонденту`);
      this.v$.$reset();

      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          taskUuid: this.prevTask.uuid,
          respondentUuid: this.prevTask.respondentUuid
        }
      })
      this.customAssessmentKey++;
    },

    getPrevTask(taskIndex, respondentIndex) {
      let result = false;

      if(taskIndex === 0) {
        const prevRespondent = this.respondents[respondentIndex - 1];
        if(prevRespondent) {
          result = this.getPrevTask(prevRespondent.tests.length, respondentIndex - 1);
        } else {
          result = false;
        }
      } else {
        const prevTask = this.respondents[respondentIndex].tests[taskIndex - 1];
        if (prevTask && prevTask.type === 'expert') {
          result = {
            ...prevTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getPrevTask(taskIndex - 1, respondentIndex);
        }
      }

      return result;
    },

    getNextTask(taskIndex, respondentIndex) {
      let result = false;

      if (taskIndex < this.respondent.tests.length - 1) {
        const nextTask = this.respondent.tests[taskIndex + 1];

        if (nextTask && nextTask.type === 'expert') {
          result = {
            ...nextTask,
            respondentUuid: this.respondents[respondentIndex].uuid
          };
        } else {
          result = this.getNextTask(taskIndex + 1, respondentIndex);
        }
      } else if(respondentIndex < this.respondents.length - 1) {
        const nextRespondent = this.respondents[respondentIndex + 1];
        if(nextRespondent) {
          result = this.getNextTask(-1, respondentIndex + 1);
        }
      }

      return result;
    },

    changeCloseAfterEvaluation() {
      localStorage.setItem('closeAfterEvaluation', this.closeAfterEvaluation);
    }
  }
}
</script>

<style lang="scss">
.custom-assessment {
  &__header {
    display: flex;
    margin-bottom: 20px;

    &__user-icon {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-radius: 100%;
      background: #F0F1F7;
      overflow: hidden;
      margin-right: 10px;

      img {
        filter: invert(29%) sepia(66%) saturate(1370%) hue-rotate(156deg) brightness(101%) contrast(101%);
        margin-bottom: -1px;
      }
    }

    &__title {
      color: #00788C;
      padding-top: 7px;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 15px;
    position: relative;
    margin: 0 -6px;

    &__btn {
      height: 48px;
      width: 100%;
    }

    .form {
      &__group {
        width: 33.33333333333%;
        padding: 6px;
        margin-bottom: 15px;
        .app-select {
          margin-top: auto;
        }

        &__checkbox {
          margin-top: 10px;

          .checkbox__label__text {
            margin-left: 10px;
          }
        }

        @include max-xs {
          height: unset;
          width: 100%;
        }
      }
    }


    &--action {
      .form__group {
        margin: -15px 0 0;
      }
    }
  }
}

</style>